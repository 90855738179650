

















import { Component, Prop, Vue } from "vue-property-decorator";
import DeliveryItem from "@/views/mine/components/DeliveryItem.vue";
import { List } from "vant";
import BlindBoxApi, { Deliver } from "@/api/blind-box.api";
import EmptyBox from "@/components/EmptyBox.vue";

@Component({
  components: { EmptyBox, DeliveryItem, List },
})
export default class DeliveryList extends Vue {
  @Prop({ default: 1 }) private readonly type!: number;

  items: Deliver[] = [];
  page = 0;
  page_size = 10;
  loading = false;
  finished = false;

  get empty(): boolean {
    const { items, finished } = this;
    return !items.length && finished;
  }

  async loadData(): Promise<void> {
    this.page++;
    const { page, page_size, items } = this;
    const rows = await BlindBoxApi.getDeliver(this.type, page, page_size).catch(
      () => []
    );
    this.loading = false;
    if (!rows.length || rows.length < this.page_size) this.finished = true;
    this.items = page > 1 ? [...items, ...rows] : rows;
  }
}
